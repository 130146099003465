.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 500px;
	justify-content: center;
	margin: auto;
}
.blink-message-wrap {
	text-align: center;
	margin: 16px;
}

.blink-message {
	font-weight: 500;
	font-size: 18px;
	line-height: 36px;
}

.canvas-container {
	width: 450px;
	height: 450px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}

.renderButton {
	width: 100%;
}
.buttonGroup {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;
	border-top: 1px solid #dfe3ec;
	padding-top: 15px;
	margin-top: 80px;
	padding-right: 24px;
	margin-left: -24px;
	margin-right: -24px;
}

.footerText {
	display: none;
}

@media screen and (max-width: 490px) {
	.canvas-container {
		width: 100%;
		height: 450px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
	}
	.canvas-wrapper {
		width: 100% !important;
	}

	.buttonGroup {
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		border-top: none;
		width: 100%;
		margin-top: 16px;
		padding-right: unset;
		margin-left: unset;
		margin-right: unset;
	}

	.buttonGroup button {
		width: 90% !important;
	}

	.footerText {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 10px;
		align-items: center;
		left: 0;
		right: 0;
	}

	.blink-message-wrap {
		text-align: center;
		margin: 16px;
		height: 40px;
	}

	.logo {
		height: 2.6vh;
		margin-top: 2px;
		margin-left: 10px;
	}
}

@media screen and (max-width: 490px) and (max-height: 728px) {
	.blink-message-wrap {
		min-height: 72px;
		height: 40px;
	}
}
