.success-screen-wrapper{
    width: max-content;
    min-height: 50vh;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
@media screen and (max-width: 499px) {
    .success-screen-wrapper{
        width: 100%
    }
    .success-screen-message {
        padding: 0 24px;
        text-align: center;
    }
}