.component-wrapper {
	position: relative;
	&__close {
		position: absolute;
		right: -8px;
		font-size: 24px;
		top: -20px;
        color: rgba($color: #000000, $alpha: .3);
        cursor: pointer;
        &:hover{
            color: unset;
        }
	}
}
@media only screen and (max-width: 499px) {
    .component-wrapper {
      position: unset;
      &__close{
        right: 8px;
        top:8px;
      }
    }
  }
