.verification-auth__container {
	display: flex;
	justify-content: center;
	align-items: center;
	max-height: 680px;
	min-height: 600px;
	max-width: 600px;
	overflow: hidden;

}
.jack-recipient-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px;
}
.jack-recipitent-img {
	width: 350px;
	min-width: 350px;
	height: 300px;
	margin: 24px 0px;
	object-fit: contain;
}
.jack-recipient-status-note{
	color: rgb(220, 55, 55) !important;
}
.jack-info-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 6px 0px;
}
.jack-location-info-wrapper{
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 6px 0px;
}
.jack-info-label {
	font-weight: 600;
	color: black;
}
.button-jack-infor {
	margin-top: 24px;
}
.waiting-screen-box {
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 580px;
	min-height: 380px;
	position: relative;
}
.jack-location-label {
	cursor: pointer;
	width: 250px;
	display: -webkit-box; 
	-webkit-box-orient: vertical; 
	-webkit-line-clamp: 4; 
	overflow: hidden; 
	text-overflow: ellipsis; 
	text-align: right;
}
.waiting-screen-desp{
	font-size: 14px;
	text-align: center;
	color: rgb(60, 56, 56);
}

.waiting-screen-close{
	position: absolute;
	right: 0px;
	top: 0px;
	cursor: pointer;
	font-size: 14px;
	padding: 4px;
	border: 1px solid black;
	border-radius: 50%;
	font-weight: 600;
}
.jack-recipient-heading{
	text-align: center;
	font-size: 24px;
	font-weight: 600;
}