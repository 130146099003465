:root {
  --color-input-border-light: #d7dff5;
  --color-white: #ffffff;
  --color-input-label-light: #6c707a;
  --color-bg-light-90: #f5f8ff;
  --color-input-text-dark: #1b1c1f;
  --color-btn-primary: #458bf5;
  --color-btn-secondary: rgba(47, 50, 61, 0.06);
  --color-text-light-80: #464C5C;
  --black: black;
  --white: #ffffff;
  --blue: blue;
  --lightblue: lightblue;
  --red: red;
  --border-gray: gray;
  --theme-gray: #f3f4f7;
  --color-header-title: #1D1E21
}
