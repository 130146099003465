@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import 'scss/_colors.scss';
/* @import "scss/_font-size.scss"; */
@import 'scss/variables/_variables.scss';
/* @import "variables/padding.scss"; */
/* @import "variables/border-radius.scss"; */
/* @import "variables/margin.scss"; */
/* @import "styles/helpers.sass" */
/* @import "styles/__helpers.sass" */
body {
	font-family: 'Poppins';
}
.App {
	display: flex;
	/* padding: 24px; */
	justify-content: center;
	align-items: center;
	background: #fff;
	border-radius: 16px;
}

.Face-Result-wrapper{
	height: 100%;
}
.verification__success {
	height: 100%;
	min-width: 440px !important;
}