@import styles/helpers

.wrapper
    align-items: center
    display: flex
    justify-content: center
    width: 100%
    height: 98vh
    background-color: rgb(242 235 235)

.inner_wrapper
    max-width: 576px
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    +m
        max-width: 576px
        padding: 0px 9px
        width: 100%

.inner
    background: #ffffff
    border-radius: 8px
    padding: 48px 32px
    width: 530px
    display: flex
    flex-direction: column
    justify-content: center
    gap: 16px
    +m
        width: unset
        padding: 0px 0px

.title
    font-weight: 600
    font-size: 24px
    line-height: 32px
    color: #17191f

.sub_title
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: #747e99
.lists
    display: flex
    flex-direction: column
    gap: 16px

.list_item
    display: flex
    gap: 8px
    align-items: center
    background: rgba(51, 184, 122, 0.06)
    border-radius: 8px
    padding: 16px
    cursor: pointer
    color: $n13
    &:hover
        background: rgba(245, 175, 69, 0.06)
        color: $s5

.left_icon
    background: rgba(51, 184, 122, 0.12)
    border-radius: 24px
    width: 40px
    height: 40px
    display: grid
    place-items: center
    font-size: 24px

.right_icon
    font-size: 24px

.method_title
    font-weight: 600
    font-size: 16px
    line-height: 24px
    color: $n14
    flex: 1

.form_wrapper
    display: flex
    flex-direction: column
    gap: 24px
    flex: 1
.input_wrapper
    border-radius: 4px
    height: 52px
    display: flex
    align-items: flex-start

    &.country-label
        max-width: 200px

.input_field
    padding: 0px 8px
    background: transparent
    color: #fff
    letter-spacing: 2px
    font-size: 16px
    width: 100%

.login_btn
    background: #0C87FD
    border-radius: 4px
    width: 100%
    height: 52px
    color: #fff
    font-weight: 500

.login_btn_disabled
    background: #1a4f9e
    border-radius: 4px
    width: 100%
    height: 52px
    color: #fff
    font-weight: 500

.back_btn
    background: gray
    border-radius: 4px
    width: 100%
    height: 52px
    color: #fff
    font-weight: 500

.footer_text
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: $n11
    cursor: pointer
    text-align: center

.footer_highlighter
    font-weight: 500
    color: $p1
    &:hover
        font-weight: 600
    +dark
        color: $white

.input_countryCode
    color: $n11
    font-weight: 500
    font-size: 16px
    margin-bottom: 2px

@media screen and (max-width: 490px)
    .wrapper
        background-color: transparent
