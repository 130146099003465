.settings{
    padding: 24px;
    &__wrapper{
        text-align: center;
    }
    &__camera-icon{
        font-size: 40px;
        color: #ed3231;
    }
    &__camera-instructions{
        padding: 24px 0px;
        font-size: 12px;
    }
    &__camera-instructions div{ font-weight: 600; font-size: 14px;}
    &__center{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 420px) {
   .settings{
    padding: unset;
   }
  }