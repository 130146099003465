@media only screen and (min-width: 500px) {
	$imageSize: 350px;
	$spacing: 10px;
	$segmentSize: 18;

	$size: 430px;
	$sizeValue: $size / 1px;
	$padding: 10px;

	$body-margin-top: 20px;
	$body-background: #fafafa;
	$body-font-weight: bold;
	$body-color: #81f;

	$rotate-animation-duration: 5.5s;

	$radius: $sizeValue / 2;
	$circumference: $radius * 3.1415926 * 2;
	$segments: round($circumference / $segmentSize);
	$angleStep: 360deg / $segments;

	$scale-transition-duration: 18%;
	$scale-transition-stay: 15%;

	@keyframes rotate {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes scale {
		0% {
			transform: scale(1, 1);
		}
		#{$scale-transition-duration} {
			transform: scale(0.01, 0.01);
		}
		#{$scale-transition-duration + $scale-transition-stay} {
			transform: scale(0.01, 0.01);
		}
		#{$scale-transition-duration
			+ $scale-transition-stay
			+ $scale-transition-duration} {
			transform: scale(1, 1);
		}
	}

	.loaderWrapper {
		display: flex;
		justify-content: center;
		width: 100%;
		align-items: center;
		height: 100%;
	}

	.Profile {
		position: relative;
		display: inline-block;
		width: $size;
		height: $size;
		line-height: $size;
		text-align: center;
	}

	.Profile-border {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		padding: 0px;
		animation: rotate $rotate-animation-duration infinite linear;
		overflow: hidden;
	}

	.Profile-border-segment {
		position: absolute;
		top: -10px;
		left: -10px;
	}

	.Profile-border-segmentContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		transform-origin: 50% 50%;
	}

	.Profile-borderMask {
		position: absolute;
		$offset: 10px;
		left: -$offset;
		right: -$offset;
		bottom: -$offset;
		top: -$offset;
	}

	@for $num from 1 through $segments {
		.Profile-border-segmentContainer:nth-child(#{$num}) {
			$currentAngle: $num * $angleStep;
			transform: rotate($currentAngle);
			.Profile-border-segment {
				transform-origin: #{$size + $padding} 50%;
				animation: scale 2.5s infinite linear;
				animation-delay: ((0.4s / $segments) * $num);
			}
		}
	}

	.Profile-imageMask {
		border-radius: 18%;
		overflow: hidden;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		vertical-align: middle;
		justify-content: center;
		align-items: center;
	}
}

@media only screen and (max-width: 450px) {
	$imageSize: 300px;
	$spacing: 10px;
	$segmentSize: 18;

	$size: $imageSize + $spacing + $spacing;
	$sizeValue: $size / 1px;
	$padding: 10px;

	$body-margin-top: 20px;
	$body-background: #fafafa;
	$body-font-weight: bold;
	$body-color: #81f;

	$rotate-animation-duration: 5.5s;

	$radius: $sizeValue / 2;
	$circumference: $radius * 3.1415926 * 2;
	$segments: round($circumference / $segmentSize);
	$angleStep: 360deg / $segments;

	$scale-transition-duration: 18%;
	$scale-transition-stay: 15%;

	@keyframes rotate {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes scale {
		0% {
			transform: scale(1, 1);
		}
		#{$scale-transition-duration} {
			transform: scale(0.01, 0.01);
		}
		#{$scale-transition-duration + $scale-transition-stay} {
			transform: scale(0.01, 0.01);
		}
		#{$scale-transition-duration
			+ $scale-transition-stay
			+ $scale-transition-duration} {
			transform: scale(1, 1);
		}
	}

	.loaderWrapper {
		display: flex;
		justify-content: center;
		width: 100%;
		align-items: center;
		height: 45vh;
	}

	.Profile {
		position: relative;
		width: 375px;
		height: 375px;
		line-height: $size;
		text-align: center;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.Profile-border {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		padding: 0px;
		animation: rotate $rotate-animation-duration infinite linear;
		overflow: hidden;
	}

	.Profile-border-segment {
		position: absolute;
		top: -10px;
		left: -10px;
	}

	.Profile-border-segmentContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		transform-origin: 50% 50%;
	}

	.Profile-borderMask {
		position: absolute;
		$offset: 10px;
		left: -$offset;
		right: -$offset;
		bottom: -$offset;
		top: -$offset;
	}

	@for $num from 1 through $segments {
		.Profile-border-segmentContainer:nth-child(#{$num}) {
			$currentAngle: $num * $angleStep;
			transform: rotate($currentAngle);
			.Profile-border-segment {
				transform-origin: #{$size + $padding} 50%;
				animation: scale 2.5s infinite linear;
				animation-delay: ((0.4s / $segments) * $num);
			}
		}
	}

	.Profile-imageMask {
		position: absolute;
		top: 5%;
		border-radius: 18%;
		overflow: hidden;
		width: 90%;
		height: 90%;
		display: flex;
		vertical-align: middle;
		justify-content: center;
		align-items: center;
	}
}

body {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	margin: 0;
}

.Profile-border-wrapper {
	height: 100%;
	position: relative;
	width: 100%;

	@media only screen and (max-width: 450px) {
		height: 84%;
		width: 84%;
	}
	> .border-circle {
		animation: growAndFade 3s infinite ease-out;
		border-radius: 18%;
		height: 100%;
		opacity: 0;
		position: absolute;
		width: 100%;
	}

	.border-circle1 {
		animation-delay: 1s;
	}
	.border-circle2 {
		animation-delay: 2s;
	}
	.border-circle3 {
		animation-delay: 3s;
	}
}
@keyframes growAndFade {
	0% {
		opacity: 0.5;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(1.1);
	}
}
@media only screen and (max-width: 450px) {
	@keyframes growAndFade {
		0% {
			opacity: 0.5;
			transform: scale(1);
		}
		100% {
			opacity: 0;
			transform: scale(1.25);
		}
	}
}
