.input-wrapper {
  .css-13cymwt-control {
    cursor: pointer;
    border-style: unset;
    background: transparent !important;
  }
  .css-t3ipsp-control {
    cursor: pointer;
    border-style: unset !important;
    border-color: unset !important;
    box-shadow: unset !important;
    background: transparent !important;
    &:hover {
      border-style: unset !important;
    }
  }
  .country-label {
    width: 180px;
  }
  .css-319lph-ValueContainer {
    padding: 2px 0px !important;
  }
  .css-tlfecz-indicatorContainer {
    padding-left: 4px;
  }
}


@media only screen and (max-width: 500px) {
  .input-wrapper .country-label {
    width: 82%;
  }
}