.waiting-wrapper {
	display: 'grid';
	place-items: 'center';
	margin: auto;
}
.waiting-wrapper-recipient {
	display: flex;
	flex-direction: column;
	height: 100% !important;
}
.wrapper-recipient {
	max-width: 500px;
	margin: auto;
}
.waiting-box {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 80vh;
}
.recipient-detail-wrapper {
	/* width: 98%; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: 8px;
	padding: 24px;
}
.icon {
	font-size: 50px;
	color: rgb(93, 196, 93);
}
.cancel-icon {
	margin-top: 16px;
	font-size: 48px;
	color: rgb(210, 55, 55);
}
.recipitent-icon {
	font-size: 40px;
	color: rgb(93, 196, 93);
}
.details-label {
	font-family: 'Poppins';
	font-style: normal;
	font-size: 15px;
	line-height: 20px;
	margin-top: -8px;
	margin-bottom: 8px;
}
.recipitent-img {
	margin-top: -30px;
	border-radius: 50%;
	width: 360px;
	height: 360px;
}
.recipitent-name {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 36px;
	margin-top: 8px;
	margin-bottom: 8px;
}
.recipitent-details-row {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 6px 0px;
}
.recipitent-details-label-type {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #7c8398;
}
.details-box {
	/* padding: 24px; */
	width: 100%;
}
.row-details {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}
.row-details-location {
	display: block;
	white-space: nowrap;
	width: 15em;
	overflow: hidden;
	text-overflow: ellipsis;
}
.succuss-gif {
	width: 72px;
	height: 72px;
	margin-bottom: 5px;
}
.recipient-next-btn {
	/* padding: 24px ; */
	margin-top: 8px;
}
.recipient-next-btn p {
	color: #008080;
}
@media screen and (max-width: 490px) {
	.waiting-box {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		height: 100%;
	}
	.waiting-wrapper-recipient {
		height: 100% !important;
		width: 100% !important;
	}
	.wrapper-recipient {
		width: 90% !important;
		margin: auto !important;
	}
	.waiting-wrapper {
		height: 100% !important;
		width: 100% !important;
	}
	.recipient-detail-wrapper {
		padding: 0 24px;
		justify-content: start;
	}
}

.waiting-loader {
	width: 100px;
	height: 100px;
}

.thanks-for-approval {
	margin: auto;
}
[data-tooltip]::before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;

    /* customizable */
    transition: all 0.15s ease;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 1px silver;
}

[data-tooltip]:hover::before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: #000000;
    margin-top: -50px;
    margin-left: 20px;
}

[data-tooltip]:not([data-tooltip-persistent])::before {
    pointer-events: none;
}

@media screen and (min-width: 490px) {
	.waiting-box {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding: 24px;
	}
	/* .details-box {
		max-width: 420px;
	} */

	/* .recipient-detail-wrapper {
		width: 98%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		gap: 8px;
	} */
}
