.wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 90vh;
	padding-top: 50px;
	background-color: white;
}

.approve-box {
	max-width: 420px;
	align-items: center;
	text-align: center;
	padding: 8px;
}

.sender {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	margin-bottom: 42px;
	width: 420px;
}
.expire-label{
	color: #515767;
}
.spinner-box{
	display: flex;
	height: 100vh;
	width: 100vw;
	justify-content: center;
	align-items: center;
}
.spinner-box img{
	width: 150px;
	height: 150px;
}

.amount-wrapper {
	position: relative;
	display: block;
	justify-content: center;
	align-items: center;
	padding: 54.2441px 13.561px;
	isolation: isolate;
	height: 110px;
	margin-top: 6px;
	margin-bottom: 6px;
	position: relative;
	background-image: url('./assests/pattern.png');
	background-size: contain;
	/* background: url(.png), #F0F4FF; */
	box-shadow: 0px 0px 3.39026px rgba(0, 0, 0, 0.12);
	border-radius: 6.78051px;
}

.sender-amount {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 40.6831px;
	line-height: 54px;
	text-align: center;
	color: #363a45;
}

.sender-Img {
	display: flex;
	justify-content: center;
}

.user-icon {
	height: 100px;
	width: 100px;
	background: #F4F4F6;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.sender-Img img {
	border-radius: 50%;
	width: 120px;
	height: 120px;
}

.dollor-icon {
	box-shadow: 0px 0px 3.39026px rgba(0, 0, 0, 0.12);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	position: absolute;
	padding: 4px;
	top: 0%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px;
	background-color: white;
	border-radius: 50%;
}

@media screen and (max-width: 490px) {
	.wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 88vh;
		padding-top: 50px;
		background-color: white;
	}

	.approve-box {
		max-width: 420px;
		align-items: center;
		text-align: center;
		padding: 8px;
		display: flex;
		flex-direction: column;
		gap: 24px
	}

	.amount-button-wrapper {
		width: 100%;
	}

	.sender {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 42px;
		width: 380px;
	}
}

@media screen and (max-width: 400px) {
	.amount-button-wrapper {
		width: 100%;
		padding-bottom: 20px;
	}

	.sender {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 42px;
		width: 100%;
	}
}