@import styles/helpers

.wrapper
    align-items: center
    display: flex
    justify-content: center
    width: 100%

.inner_wrapper
    max-width: 576px
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    +m
        max-width: 576px
        padding: 0px 9px
        width: 100%
        display: flex
        flex-direction: column
        justify-content: space-between
    +s
        height: 82.5vh
.inner_wrapper_fromBank
    justify-content: center

.inner
    background: #ffffff
    border-radius: 8px
    padding: 10px
    width: 530px
    display: flex
    flex-direction: column
    gap: 16px
    +m
    width: unset

.title
    font-weight: 600
    font-size: 24px
    line-height: 32px
    color: #17191f

.sub_title
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: #747e99
.lists
    display: flex
    flex-wrap: wrap
    gap: 16px
    justify-content: center

.list_item
    max-width: 30%
    gap: 8px
    align-items: center
    align-items: center

    border-radius: 8px
    padding: 16px 36px
    cursor: pointer
    color: #7935DE
    border: 1px solid #DFE3EC
    &:hover
        border: 1px solid #2251D3
        .fillCircle
            border: 1px solid #2251D3
        .filled
            background: #2251D3
    +s
        max-width: 100%
        width: 100%

.fillCircle
    width: 16px
    height: 16px
    border: 1px solid #DFE3EC
    border-radius: 24px
    position: relative
    right: 25px
    bottom: 4px

.filled
    width: 10px
    height: 10px
    border-radius: 24px
    left: 3px
    top: 3px
    position: absolute

.left_icon
    background: rgba(121, 53, 222, 0.12)
    border-radius: 24px
    width: 80px
    height: 80px
    display: grid
    place-items: center
    font-size: 54px

.right_icon
    font-size: 24px

.method_title
    text-align: center
    font-size: 12px
    line-height: 26px
    color: #464c5c
    font-weight: 500
    flex: 1

.back_btn
    display: flex
    gap: 8px
    width: 40%
    align-items: center
    background: gray
    border-radius: 8px
    padding: 16px
    text-align: center
    height: 40px
    align-self: center
    cursor: pointer
    color: $n13
    &:hover
        background: rgba(245, 175, 69, 0.06)
        color: $s5

.edit_Btn
    cursor: pointer
    text-decoration: underline
    color: $p1
    &:hover
        color: $p1

.chooseMethod_btn
    display: flex
    gap: 16px
    width: 100%
    justify-content: flex-end
    +s 
        flex-direction: column-reverse


@media screen and (max-width: 490px)
    .wrapper
        align-items: center
        display: flex
        justify-content: center
        width: 100%
        height: 86vh
