.ban-poc-loader  {
    animation: rotate 1s infinite;  
    height: 50px;
    width: 50px;
    margin-bottom: 0.5rem;
  }
  
  .ban-poc-loader:before,
  .ban-poc-loader:after {   
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;  
    width: 20px;
  }
  .ban-poc-loader:before {
    animation: ball1 1s infinite;  
    background-color: #0051CC;
    box-shadow: 30px 0 0 #4e617e;
    margin-bottom: 10px;
  }
  .ban-poc-loader:after {
    animation: ball2 1s infinite; 
    background-color: #0051CC;
    box-shadow: 30px 0 0 #4e617e;
  }
  
  @keyframes rotate {
    0% { 
      -webkit-transform: rotate(0deg) scale(0.8); 
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% { 
      -webkit-transform: rotate(360deg) scale(1.2); 
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% { 
      -webkit-transform: rotate(720deg) scale(0.8); 
      -moz-transform: rotate(720deg) scale(0.8);
    }
  }
 




 