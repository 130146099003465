.renderStatus {
	text-align: center;
	margin-top: 20px;
	font-size: 24px;
}
.canvas-wrapper {
	position: relative;
}
#output {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.canvas-wrapper svg {
	fill: none;
	stroke: #8a3ab9;
	stroke-linecap: round;
	stroke-width: 1;
	stroke-dasharray: 1;
	stroke-dashoffset: 0;
	animation: stroke-draw 6s ease-out infinite alternate;
}
@keyframes stroke-draw {
	from {
		stroke: #8a3ab9;
		stroke-dasharray: 1;
	}
	to {
		stroke: #cd486b;
		transform: rotate(180deg);
		stroke-dasharray: 8;
	}
}
